/**
 * Update date: 14-06-2023
 * Template file upload preview component
 */
import { useApp } from 'app';
import useAxios from 'axios-hooks';
import { Button } from 'devextreme-react';
import FileUploader from 'devextreme-react/file-uploader';
import useDictionary from 'hooks/useDictionary';
import iconDocument from 'images/icon_document.svg';
import iconUpload from 'images/icon_upload.svg';
import React, { useRef, useState } from 'react';
import { notification } from 'utils/notification';
import PopupPreviewImg from '../PopupCommon/PopupPreviewImg';

const FileUploadPreviews = props => {
  const { themePro }: any = useApp();
  const { request, value, onChangeValue } = props?.data?.editorOptions || {};
  const fileUploaderRef: any = useRef(null);
  const [popupPreview, setPopupPreview] = useState<any>({
    visible: false,
    url: '',
  });
  const [{}, refetchUploadFiles] = useAxios({}, { manual: true });
  const { t } = useDictionary({});
  /**
   * on set file upload
   *
   * @param {*} e
   * @return {*}
   */
  const setFileUploader = async e => {
    if (e.value.length === 0) return;
    const formData = new FormData();
    const filesList = e.value;

    for (let i = 0; i < filesList.length; i++) {
      fileUploaderRef?.current?.instance?.removeFile(i);
      const file = filesList[i];
      formData.append('files', file);
      if (file.size > 5000000 || !file) {
        notification({
          message: t('File must be less than 5MB'),
          type: 'error',
        });
        return;
      }
    }
    const res = await refetchUploadFiles({
      url: request?.url,
      method: request?.method,
      data: formData,
    });
    notification({
      res,
    });
    const dataRes = res?.data?.data;
    const newValue = value?.concat(dataRes);
    onChangeValue(newValue);
  };
  const onErrorImageDefault = e => {
    return (e.target.src = iconDocument);
  };
  /**
   * on delete file
   *
   * @param {*} e
   * @param {*} index
   */
  const onDeleteFile = (e, index: any) => {
    value?.splice(index, 1);
    onChangeValue([...value]);
    e?.event.stopPropagation();
  };
  /**
   * render file upload
   *
   */
  const FileUploadRender = () => (
    <div style={{ display: 'flex' }}>
      {value?.map((item, index) => (
        <div
          key={index}
          style={{
            margin: '10px 10px 0 0',
            alignItems: 'center',
            border: '1px solid #ccc',
            position: 'relative',
            maxWidth: 140,
            width: '100%',
          }}
        >
          <img
            height={70}
            width="100%"
            src={item.documentUrl}
            onError={onErrorImageDefault}
            alt=""
          />
          <Button
            icon="close"
            onClick={e => onDeleteFile(e, index)}
            stylingMode="text"
            type="default"
            style={{ position: 'absolute', right: 0, top: 0 }}
          />
          <div
            style={{
              margin: 5,
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              wordBreak: 'break-word',
              textDecoration: 'none',
              cursor: 'pointer',
            }}
            onClick={() => {
              // setPopupPreview({
              //   visible: true,
              //   url: item?.documentUrl,
              // });
              if (!item?.documentUrl) return;
              window.open(item?.documentUrl, '_blank');
            }}
          >
            {item.documentNm}
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <>
      <div
        id="dropzone-external-file-upload-preview"
        style={
          themePro
            ? {
                border: '1px dashed #98A2B3',
                borderRadius: 5,
                padding: 20,
                color: '#B54708',
              }
            : {
                border: '2px solid #a7a7a7',
                borderRadius: 5,
                padding: 20,
                color: '#707070',
              }
        }
      >
        {!themePro && (
          <div>
            <img height={20} src={iconUpload} alt="" />
            {t('Please select your file here')}
          </div>
        )}
        <div style={{ textAlign: 'center' }}>
          <div className="drop-zone-label2">
            {t('Drag and Drop Here Or Browser Files')}
          </div>
          <div style={{ color: '#c4161c' }}>
            {t('File must be less than 5MB')}
          </div>
        </div>
      </div>
      <FileUploadRender />
      <FileUploader
        multiple={true}
        uploadMode="instantly"
        ref={fileUploaderRef}
        dialogTrigger="#dropzone-external-file-upload-preview"
        dropZone="#dropzone-external-file-upload-preview"
        onValueChanged={setFileUploader}
        visible={false}
        maxFileSize={5000000}
      />
      {popupPreview?.visible && (
        <PopupPreviewImg
          visible={popupPreview}
          url={popupPreview?.url || ''}
          onHiding={() =>
            setPopupPreview({
              visible: false,
              url: '',
            })
          }
        />
      )}
    </>
  );
};

export default FileUploadPreviews;
